/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

function About() {
  return (
    <div className="about">
      <div className="container">
        <h2>About</h2>
      </div>
    </div>
  );
}

export default About;
